import React from "react";
import { Image } from "semantic-ui-react";
import { Icon } from "../../assets";
import { AdminMenu, Logout, Profile } from "../../components/Admin/AdminLayout";

import "./AdminLayout.scss";

export function AdminLayout(props) {
  const { children } = props;

  return (
    <div className="admin-layout">
      <div className="admin-layout__left">
        <Image src={Icon.LogoWhite} className="logo" />
        <AdminMenu />
      </div>
      <div className="admin-layout__right">
        <div className="admin-layout__right-header">
          <Profile />
          <Logout />
        </div>
        <div className="admin-layout__right-content">{children}</div>
      </div>
    </div>
  );
}
