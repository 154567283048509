import React, { useEffect, useState } from "react";
import { Form } from "semantic-ui-react";
import { useFormik } from "formik";
import { User, Sector } from "../../../../api";
import { useAuth } from "../../../../hooks";
import { initialValues, validationSchema } from "./UserForm.form";

import "./UserForm.scss";
import { includes, map } from "lodash";

const userController = new User();
const sectorController = new Sector();

export function UserForm(props) {
  const { close, onReload, user } = props;
  const { accessToken } = useAuth();
  const [sectors, setSectors] = useState([]);

  const formik = useFormik({
    initialValues: initialValues(user),
    validationSchema: validationSchema(user),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        if (!user) {
          await userController.createUser(accessToken, formValue);
        } else {
          await userController.updateUser(accessToken, user._id, formValue);
        }
        onReload();
        close();
      } catch (error) {
        console.error(error);
      }
    },
  });

  useEffect(() => {
    (async () => {
      const sectors = await sectorController.getSectors(true);
      const data = map(sectors, (sector) => ({
        key: sector._id,
        text: sector.name,
        value: sector._id,
      }));
      setSectors(data);
    })();
  }, []);

  return (
    <Form className="user-form" onSubmit={formik.handleSubmit}>
      <Form.Group widths="equal">
        <Form.Input
          name="firstname"
          placeholder="Nombre"
          onChange={formik.handleChange}
          value={formik.values.firstname}
          error={formik.errors.firstname}
        />
        <Form.Input
          name="lastname"
          placeholder="Apellidos"
          onChange={formik.handleChange}
          value={formik.values.lastname}
          error={formik.errors.lastname}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Input
          name="email"
          placeholder="Correo electronico"
          onChange={formik.handleChange}
          value={formik.values.email}
          error={formik.errors.email}
        />
        <Form.Dropdown
          placeholder="Seleccióna un rol"
          options={roleOptions}
          selection
          multiple
          onChange={(_, data) => formik.setFieldValue("role", data.value)}
          value={formik.values.role}
          error={formik.errors.role}
        />
      </Form.Group>
      <Form.Input
        type="password"
        name="password"
        placeholder="Contraseña"
        onChange={formik.handleChange}
        value={formik.values.password}
        error={formik.errors.password}
      />
      {includes(formik.values.role, "seller") && (
        <Form.Group widths="equal">
          <Form.Dropdown
            placeholder="Seleccióna un sector"
            options={sectors}
            selection
            multiple
            onChange={(_, data) => formik.setFieldValue("sector", data.value)}
            value={formik.values.sector}
            error={formik.errors.sector}
          />
          <Form.Input
            name="box"
            placeholder="Numero de box"
            onChange={formik.handleChange}
            value={formik.values.box}
            error={formik.errors.box}
          />
        </Form.Group>
      )}
      <Form.Button type="submit" primary fluid loading={formik.isSubmitting}>
        {user ? "Actualizar usuario" : "Crear usuario"}
      </Form.Button>
    </Form>
  );
}

const roleOptions = [
  {
    key: "admin",
    text: "Administrador",
    value: "admin",
  },
  {
    key: "seller",
    text: "Vendedor",
    value: "seller",
  },
];
