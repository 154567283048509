import { includes } from "lodash";
import * as Yup from "yup";

export function initialValues(user) {
  return {
    firstname: user?.firstname || "",
    lastname: user?.lastname || "",
    email: user?.email || "",
    role: user?.role || [],
    password: "",
    sector: user?.sector || [],
    box: user?.box || "",
  };
}

export function validationSchema(user) {
  return Yup.object({
    firstname: Yup.string().required(true),
    lastname: Yup.string().required(true),
    email: Yup.string().email(true).required(true),
    role: Yup.array().required(true),
    password: user ? Yup.string() : Yup.string().required(true),
    sector: includes(user?.role, "seller")
      ? Yup.array().required(true)
      : Yup.array(),
    box: includes(user?.role, "seller")
      ? Yup.string().required(true)
      : Yup.string(),
  });
}
