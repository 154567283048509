import React, { useEffect, useState } from "react";
import { Tab, Button, Icon } from "semantic-ui-react";
import { ListTurns } from "../../../components/Admin/Turns";
import { useAuth } from "../../../hooks";
import { useStopwatch } from "react-timer-hook";
import { includes } from "lodash";
import { User, Status } from "../../../api";

import "./Turns.scss";

const userController = new User();
const statusController = new Status();

export function Turns() {
  const { user, accessToken } = useAuth();
  const [reload, setReload] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [isBreak, setIsBreak] = useState(false);

  const { seconds, minutes, hours, start, reset, isRunning } = useStopwatch();

  useEffect(() => {
    (async () => await Notification.requestPermission())();
  }, []);

  const onReload = () => setReload((prevState) => !prevState);

  const onBreak = async () => {
    if (!isRunning) {
      start();
      setIsBreak(true);
      await userController.updateUser(accessToken, user._id, {
        status: "Descansando",
      });
    } else {
      const time = `${("0" + hours).slice(-2)}:${("0" + minutes).slice(-2)}:${(
        "0" + seconds
      ).slice(-2)}`;
      reset(0, false);
      setIsBreak(false);
      await userController.updateUser(accessToken, user._id, {
        status: "Disponible",
      });
      await statusController.createStatus(accessToken, {
        user: user._id,
        time,
        status: "Descansando",
      });
    }
  };

  const onBusy = async () => {
    if (!isRunning) {
      start();
      setIsBusy(true);
      await userController.updateUser(accessToken, user._id, {
        status: "Ocupado",
      });
    } else {
      const time = `${("0" + hours).slice(-2)}:${("0" + minutes).slice(-2)}:${(
        "0" + seconds
      ).slice(-2)}`;
      reset(0, false);
      setIsBusy(false);
      await userController.updateUser(accessToken, user._id, {
        status: "Disponible",
      });
      await statusController.createStatus(accessToken, {
        user: user._id,
        time,
        status: "Ocupado",
      });
    }
  };

  const panes = [
    {
      menuItem: "Turnos Pendientes",
      render: () => (
        <Tab.Pane attached={false}>
          <ListTurns turnsActive={true} reload={reload} onReload={onReload} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Turnos Finalizados",
      render: () => (
        <Tab.Pane attached={false}>
          <ListTurns turnsActive={false} reload={reload} onReload={onReload} />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <div className="turns-page">
      {includes(user.role, "seller") && (
        <>
          {isRunning && (
            <div className="turns-page__timer">
              <span>{("0" + hours).slice(-2)}</span>:
              <span>{("0" + minutes).slice(-2)}</span>:
              <span>{("0" + seconds).slice(-2)}</span>
            </div>
          )}
          <Button icon className="turns-page__busy" primary onClick={onBusy}>
            {isBusy ? <Icon name="stop" /> : "Ocupado"}
          </Button>
          <Button icon className="turns-page__break" primary onClick={onBreak}>
            {isBreak ? <Icon name="stop" /> : "Descansar"}
          </Button>
        </>
      )}
      <Tab menu={{ secondary: true }} panes={panes} />
    </div>
  );
}
