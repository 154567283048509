import React from "react";
import { Container, Image } from "semantic-ui-react";
import { TopBar } from "../../components/Web";
import { Icon } from "../../assets";

import "./ClientLayout.scss";

export function ClientLayout(props) {
  const { children } = props;

  return (
    <div className="client-layout">
      <div className="client-layout__header">
        <TopBar />
      </div>
      {children}
      <div className="client-layout__footer">
        <Container>
          <span>TODOS LOS DERECHOS RESERVADOS</span>
          <Image src={Icon.LogoWAIA} className="logo" />
        </Container>
      </div>
    </div>
  );
}
