import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Form } from "semantic-ui-react";
import { initialValues, validationSchema } from "./TurnForm.form";
import { User, Turn } from "../../../../api";
import { useAuth } from "../../../../hooks";
import { filter, includes, map } from "lodash";

const userController = new User();
const turnController = new Turn();

export function TurnForm(props) {
  const { close, onReload, turn } = props;
  const { accessToken } = useAuth();
  const [sellers, setSellers] = useState([]);

  useEffect(() => {
    (async () => {
      const users = await userController.getUsers(accessToken, true, 1, 100);
      const sellers = filter(users.docs, (user) =>
        includes(user.role, "seller")
      );
      const data = map(sellers, (seller) => ({
        key: seller._id,
        text: `${seller.firstname} ${seller.lastname}`,
        value: seller?._id,
      }));
      setSellers(data);
    })();
  }, []);

  const formik = useFormik({
    initialValues: initialValues(turn?.seller?._id),
    validationSchema: validationSchema(),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        await turnController.updateTurn(accessToken, turn?._id, {
          seller: formValue.seller,
        });
        close();
        onReload();
      } catch (error) {
        console.error(error);
      }
    },
  });

  return (
    <Form className="turn-form" onSubmit={formik.handleSubmit}>
      <Form.Dropdown
        placeholder="Seleccióna un vendedor"
        options={sellers}
        selection
        onChange={(_, data) => formik.setFieldValue("seller", data.value)}
        value={formik.values.seller}
        error={formik.errors.seller}
      />
      <Form.Button type="submit" primary fluid loading={formik.isSubmitting}>
        Actualizar turno
      </Form.Button>
    </Form>
  );
}
