const BASE_PATH =
  process.env.REACT_APP_ENV === "production"
    ? process.env.REACT_APP_BASE_PATH_PRD
    : process.env.REACT_APP_BASE_PATH_DEV;
const BASE_API =
  process.env.REACT_APP_ENV === "production"
    ? process.env.REACT_APP_BASE_API_PRD
    : process.env.REACT_APP_BASE_API_DEV;

export const ENV = {
  BASE_PATH: BASE_PATH,
  AUTH: {
    BASE_API: BASE_API,
    API_ROUTES: {
      REGISTER: "auth/register",
      LOGIN: "auth/login",
      REFRESH_ACCESS_TOKEN: "auth/refresh_access_token",
    },
  },
  USER: {
    BASE_API: BASE_API,
    API_ROUTES: {
      USER_ME: "user/me",
      USERS: "users",
      USER: "user",
    },
  },
  SECTOR: {
    BASE_API: BASE_API,
    API_ROUTES: {
      SECTORS: "sectors",
      SECTOR: "sector",
    },
  },
  CLIENT: {
    BASE_API: BASE_API,
    API_ROUTES: {
      CLIENTS: "clients",
      CLIENT: "client",
    },
  },
  TURN: {
    BASE_API: BASE_API,
    API_ROUTES: {
      TURNS: "turns",
      TURN: "turn",
      STATUS: "turns/status",
    },
  },
  STATUS: {
    BASE_API: BASE_API,
    API_ROUTES: {
      STATUS: "status",
    },
  },
  JWT: {
    ACCESS: "access",
    REFRESH: "refresh",
  },
};
