// import { ReactComponent as LogoWhite } from "./svg/tincode-white.svg";
import LogoWhite from "./png/logo.png";
import Logo from "./png/logo.png";
import LogoWAIA from "./png/LogoWAIA.png";

import authBg from "./jpg/auth-bg.jpg";
import homeBanner from "./jpg/home-banner.jpg";
import noAvatar from "./png/no-avatar.png";
import academyLogo from "./png/academy-logo.png";

const Icon = {
  LogoWhite,
  Logo,
  LogoWAIA,
};

const image = {
  authBg,
  homeBanner,
  noAvatar,
  academyLogo,
};

export { Icon, image };
