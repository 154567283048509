import React, { useState, useEffect } from "react";
import { Loader, Pagination } from "semantic-ui-react";
import { size, map } from "lodash";
import { Turn } from "../../../../api";
import { TurnItem } from "../TurnItem";
import NewClient from "../../../../assets/png/newclient.png";
import "./ListTurns.scss";
import { useAuth } from "../../../../hooks";

const turnController = new Turn();

export function ListTurns(props) {
  const { turnsActive, reload, onReload } = props;
  const [turns, setTurns] = useState(null);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState();
  const { user } = useAuth();

  async function checkPageStatus() {
    if (!("Notification" in window)) {
      console.error("This browser does not support system notifications!");
    } else if (Notification.permission === "granted") {
      sendNotification();
    } else if (Notification.permission !== "denied") {
      await Notification.requestPermission((permission) => {
        if (permission === "granted") {
          sendNotification();
        }
      });
    }
  }

  function sendNotification() {
    const notification = new Notification("Nuevo Cliente", {
      icon: NewClient,
      body: `Hay un nuevo cliente esperando antencion.`,
    });

    notification.onclick = () =>
      function () {
        window.open("https://dashboard.neumaticosverona.com.ar/admin/turns");
      };
  }

  useEffect(() => {
    (async () => {
      try {
        const response = await turnController.getTurns(
          turnsActive,
          user?._id,
          page,
          7
        );
        if (turnsActive) {
          if (
            response.docs.length > turns?.length &&
            response.docs.length > 0
          ) {
            if (response.docs[0]?._id !== turns[0]?._id) {
              checkPageStatus();
            }
          }
        }
        setTurns(response.docs);
        setPagination({
          limit: response.limit,
          page: response.page,
          pages: response.totalPages,
          total: response.totalDocs,
        });
      } catch (error) {
        console.error(error);
      }
      setTimeout(() => {
        onReload();
      }, 10000);
    })();
  }, [turnsActive, reload, page]);

  const changePage = (_, data) => {
    setPage(data.activePage);
  };

  if (!turns) return <Loader active inline="centered" />;
  if (size(turns) === 0) return "No hay ningun turno";

  return (
    <div className="list-turns">
      {map(turns, (turn) => (
        <TurnItem key={turn?._id} turn={turn} onReload={onReload} />
      ))}

      <div className="list-turns__pagination">
        <Pagination
          totalPages={pagination.pages}
          defaultActivePage={pagination.page}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          onPageChange={changePage}
        />
      </div>
    </div>
  );
}
