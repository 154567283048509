import { ENV } from "../utils";

export class Status {
  baseApi = ENV.STATUS.BASE_API;

  async createStatus(accessToken, data) {
    try {
      const url = `${this.baseApi}/${ENV.STATUS.API_ROUTES.STATUS}`;
      const params = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      };

      const response = await fetch(url, params);
      const result = response.json();
      if (response.status !== 200) throw result;
      return result;
    } catch (error) {
      throw error;
    }
  }

  async getStatus() {
    try {
      const url = `${this.baseApi}/${ENV.STATUS.API_ROUTES.STATUS}`;
      const response = await fetch(url);
      const result = response.json();
      if (response.status !== 200) throw result;
      return result;
    } catch (error) {
      throw error;
    }
  }

  async updateStatus(accessToken, idStatus, data) {
    try {
      const url = `${this.baseApi}/${ENV.STATUS.API_ROUTES.STATUS}/${idStatus}`;
      const params = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      };

      const response = await fetch(url, params);
      const result = response.json();
      if (response.status !== 200) throw result;
      return result;
    } catch (error) {
      throw error;
    }
  }

  async deleteStatus(accessToken, idStatus) {
    try {
      const url = `${this.baseApi}/${ENV.STATUS.API_ROUTES.STATUS}/${idStatus}`;
      const params = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const response = await fetch(url, params);
      const result = response.json();
      if (response.status !== 200) throw result;
      return result;
    } catch (error) {
      throw error;
    }
  }
}
