import React, { useEffect, useState } from "react";
import ImageVerona from "../../../assets/png/background.png";
import NotificationSound from "../../../assets/audio/notification.wav";
import ReactHowler from "react-howler";
import { ListTurns } from "../../../components/Web/Turns";
import { Turn } from "../../../api";

import "./Monitor.scss";
import { Image } from "semantic-ui-react";

const turnController = new Turn();

export function Monitor() {
  const [tunsWaiting, setTunsWaiting] = useState(null);
  const [turnCalled, setTurnCalled] = useState(null);
  const [notification, setNotification] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const fetch = async () => {
    const response = await turnController.getTurnsByStatus("waiting", 1, 5);
    setTunsWaiting(response.docs);
  };

  const fetchCalled = async () => {
    setNotification(false);
    const response = await turnController.getTurnsByStatus("called", 1, 1);
    if (turnCalled && response.docs[0]?._id !== turnCalled[0]?._id) {
      setNotification(true);
    }
    setTurnCalled(response.docs);
  };

  useEffect(() => {
    (async () => {
      await fetch();
      setTimeout(() => {
        setRefresh((prevState) => !prevState);
      }, 10000);
    })();
  }, [refresh]);

  useEffect(() => {
    (async () => {
      await fetchCalled();
    })();
  }, [refresh]);

  return (
    <>
      <div className="monitor">
        <div className="monitor__waiting">
          <h1>TURNOS EN ESPERA</h1>
        </div>
        <div className="monitor__info">
          <div className="monitor__info-video">
            <Image src={ImageVerona} />
          </div>
          <div className="monitor__info-turns">
            <ListTurns turns={tunsWaiting} type={"waiting"} />
          </div>
        </div>
        <div className="monitor__call">
          <h1>TURNO ACTUAL</h1>
          <div className="monitor__call-turns">
            <ListTurns turns={turnCalled} type={"called"} />
          </div>
        </div>
      </div>
      <ReactHowler src={NotificationSound} playing={notification} />
    </>
  );
}
