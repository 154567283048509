import React from "react";
import { Container, Header } from "semantic-ui-react";

import "./Error.scss";

export function Error() {
  return (
    <Container className="error">
      <Header>Error 404</Header>
      <p>No pudimos encontrar la pagina a la que intenta acceder.</p>
    </Container>
  );
}
