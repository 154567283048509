import React from "react";
import { Container } from "semantic-ui-react";
import { ItemTurn } from "../../../components/Web/Turns";

import "./Turn.scss";

export function Turn() {
  return (
    <Container className="turn">
      <ItemTurn />
    </Container>
  );
}
