import React, { useState, useEffect } from "react";
import { Loader, Pagination } from "semantic-ui-react";
import { size, map } from "lodash";
import { Client } from "../../../../api";
import { ClientItem } from "../ClientItem";

import "./ListClients.scss";

const clientController = new Client();

export function ListClients(props) {
  const { clientsActive, reload, onReload } = props;
  const [clients, setClients] = useState(null);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState();

  useEffect(() => {
    (async () => {
      try {
        const response = await clientController.getClients(
          clientsActive,
          null,
          page,
          7
        );
        setClients(response.docs);
        setPagination({
          limit: response.limit,
          page: response.page,
          pages: response.totalPages,
          total: response.totalDocs,
        });
      } catch (error) {
        console.error(error);
      }
    })();
  }, [clientsActive, reload, page]);

  const changePage = (_, data) => {
    setPage(data.activePage);
  };

  if (!clients) return <Loader active inline="centered" />;
  if (size(clients) === 0) return "No hay ningun cliente";

  return (
    <div className="list-clients">
      {map(clients, (client) => (
        <ClientItem key={client._id} client={client} onReload={onReload} />
      ))}

      <div className="list-clients__pagination">
        <Pagination
          totalPages={pagination.pages}
          defaultActivePage={pagination.page}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
          onPageChange={changePage}
        />
      </div>
    </div>
  );
}
