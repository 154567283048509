import React, { useState } from "react";
import { Tab, Button } from "semantic-ui-react";
import { BasicModal } from "../../../components/Shared";
import { SectorForm, ListSectors } from "../../../components/Admin/Sectors";

import "./Sectors.scss";

export function Sectors() {
  const [showModal, setShowModal] = useState(false);
  const [reload, setReload] = useState(false);

  const onOpenCloseModal = () => setShowModal((prevState) => !prevState);
  const onReload = () => setReload((prevState) => !prevState);

  const panes = [
    {
      menuItem: "Sectores activos",
      render: () => (
        <Tab.Pane attached={false}>
          <ListSectors
            sectorsActive={true}
            reload={reload}
            onReload={onReload}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Sectores inactivos",
      render: () => (
        <Tab.Pane attached={false}>
          <ListSectors
            sectorsActive={false}
            reload={reload}
            onReload={onReload}
          />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <>
      <div className="sectors-page">
        <Button
          className="sectors-page__add"
          primary
          onClick={onOpenCloseModal}
        >
          Nuevo sector
        </Button>
        <Tab menu={{ secondary: true }} panes={panes} />
      </div>
      <BasicModal
        show={showModal}
        close={onOpenCloseModal}
        title="Crear nuevo sector"
      >
        <SectorForm close={onOpenCloseModal} onReload={onReload} />
      </BasicModal>
    </>
  );
}
