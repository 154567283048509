import React, { useState } from "react";
import { Tab, Button } from "semantic-ui-react";
import { BasicModal } from "../../../components/Shared";
import { ClientForm, ListClients } from "../../../components/Admin/Clients";
import { Client } from "../../../api";

import "./Clients.scss";

const clientController = new Client();

export function Clients() {
  const [showModal, setShowModal] = useState(false);
  const [reload, setReload] = useState(false);

  const onOpenCloseModal = () => setShowModal((prevState) => !prevState);
  const onReload = () => setReload((prevState) => !prevState);

  const panes = [
    {
      menuItem: "Clientes",
      render: () => (
        <Tab.Pane attached={false}>
          <ListClients
            clientsActive={true}
            reload={reload}
            onReload={onReload}
          />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <>
      <div className="clients-page">
        <div className="clients-page__buttons">
          <Button
            className="clients-page__buttons-add"
            primary
            onClick={onOpenCloseModal}
          >
            Nuevo cliente
          </Button>
        </div>
        <Tab menu={{ secondary: true }} panes={panes} />
      </div>
      <BasicModal
        show={showModal}
        close={onOpenCloseModal}
        title="Crear nuevo cliente"
      >
        <ClientForm close={onOpenCloseModal} onReload={onReload} />
      </BasicModal>
    </>
  );
}
