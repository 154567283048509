import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { useReactToPrint } from "react-to-print";
import "./ItemTurn.scss";

export function ItemTurn() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const ticket = useRef();

  const handlePrint = useReactToPrint({
    content: () => ticket.current,
    pageStyle: "",
  });

  useEffect(() => {
    handlePrint();
  }, []);

  const TicketToPrint = () => {
    return (
      <center ref={ticket} className="ticket" style={{ maxWidth: 500 }}>
        <h3>Neumaticos Verona</h3>
        <h2>Bienvenido</h2>
        <p>Por favor retire su turno</p>
        <p>y espere ser atendido por el número:</p>
        <p style={{ fontWeight: "bold", fontSize: 12 }}>{state.sector.name}</p>
        <h1>
          {state.sector.initials}-{("0" + state.turn.data.number).slice(-2)}
        </h1>
        <p style={{ fontWeight: "bold", fontSize: 12 }}>
          Box {state?.turn?.seller?.box}
        </p>
        <p>Gracias por su visita</p>
      </center>
    );
  };

  if (!state) return navigate("/");

  const onFinish = () => {
    navigate("/h45h-w414.v3r0n4!");
  };

  return (
    <div className="item-turn">
      <TicketToPrint />
      <div className="divider"></div>
      <div className="button">
        <Button primary fluid onClick={onFinish}>
          Finalizar
        </Button>
      </div>
    </div>
  );
}
