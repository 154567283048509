import React from "react";
import { Container, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { Icon } from "../../../assets";

import "./TopBar.scss";

export function TopBar() {
  return (
    <div className="top-bar">
      <Container>
        <div className="top-bar__left">
          <Link to="/" className="logo">
            <Image src={Icon.LogoWhite} />
          </Link>
          {/* <div className="menu">
            <a href={"/"}>Home</a>
          </div> */}
        </div>
        <div></div>
      </Container>
    </div>
  );
}
