import React from "react";
import { Form } from "semantic-ui-react";
import { useFormik } from "formik";
import { Sector } from "../../../../api";
import { useAuth } from "../../../../hooks";
import { initialValues, validationSchema } from "./SectorForm.form";

import "./SectorForm.scss";

const sectorController = new Sector();

export function SectorForm(props) {
  const { close, onReload, sector } = props;
  const { accessToken } = useAuth();

  const formik = useFormik({
    initialValues: initialValues(sector),
    validationSchema: validationSchema(),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        if (!sector) {
          await sectorController.createSector(accessToken, formValue);
        } else {
          await sectorController.updateSector(
            accessToken,
            sector._id,
            formValue
          );
        }
        onReload();
        close();
      } catch (error) {
        console.error(error);
      }
    },
  });

  return (
    <Form className="sector-form" onSubmit={formik.handleSubmit}>
      <Form.Group widths="equal">
        <Form.Input
          name="name"
          placeholder="Nombre"
          onChange={formik.handleChange}
          value={formik.values.name}
          error={formik.errors.name}
        />
        <Form.Input
          name="initials"
          placeholder="Iniciales"
          onChange={formik.handleChange}
          value={formik.values.initials}
          error={formik.errors.initials}
        />
      </Form.Group>
      <Form.Button type="submit" primary fluid loading={formik.isSubmitting}>
        {sector ? "Actualizar sector" : "Crear sector"}
      </Form.Button>
    </Form>
  );
}
