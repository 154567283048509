import { ENV } from "../utils";

export class Client {
  baseApi = ENV.CLIENT.BASE_API;

  async createClient(data) {
    try {
      const url = `${this.baseApi}/${ENV.CLIENT.API_ROUTES.CLIENT}`;
      const params = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };

      const response = await fetch(url, params);
      const result = response.json();
      if (response.status !== 200) throw result;
      return result;
    } catch (error) {
      throw error;
    }
  }

  async getClients(active, dni, page, limit) {
    try {
      const activeFilter = active ? `active=${active}` : null;
      const dniFilter = dni ? `dni=${dni}` : null;
      const pageFilter = `page=${page || 1}`;
      const limitFilter = `limit=${limit || 10}`;
      const url = `${this.baseApi}/${ENV.CLIENT.API_ROUTES.CLIENTS}?${activeFilter}&${dniFilter}&${pageFilter}&${limitFilter}`;
      const response = await fetch(url);
      const result = response.json();
      if (response.status !== 200) throw result;
      return result;
    } catch (error) {
      throw error;
    }
  }

  async updateClient(accessToken, idClient, data) {
    try {
      const url = `${this.baseApi}/${ENV.CLIENT.API_ROUTES.CLIENT}/${idClient}`;
      const params = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      };

      const response = await fetch(url, params);
      const result = response.json();
      if (response.status !== 200) throw result;
      return result;
    } catch (error) {
      throw error;
    }
  }

  async deleteClient(accessToken, idClient) {
    try {
      const url = `${this.baseApi}/${ENV.CLIENT.API_ROUTES.CLIENT}/${idClient}`;
      const params = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const response = await fetch(url, params);
      const result = response.json();
      if (response.status !== 200) throw result;
      return result;
    } catch (error) {
      throw error;
    }
  }
}
