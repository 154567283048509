import React, { useState } from "react";
import { Image, Button, Icon, Confirm } from "semantic-ui-react";
import { image } from "../../../../assets";
import { Turn, User, Status } from "../../../../api";
import { useAuth } from "../../../../hooks";
import { includes } from "lodash";
import { useStopwatch } from "react-timer-hook";
import { BasicModal } from "../../../Shared";
// import { ClientForm } from "../../Clients";
import { TurnForm } from "../TurnForm";

import "./TurnItem.scss";

const turnController = new Turn();
const userController = new User();
const statusController = new Status();

export function TurnItem(props) {
  const { turn, onReload } = props;
  const { accessToken, user } = useAuth();
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  // const [showModal, setShowModal] = useState(false);
  const [showModalSellers, setshowModalSellers] = useState(false);

  console.log(user)

  // const onOpenCloseModal = () => setShowModal((prevState) => !prevState);
  const onOpenCloseModalSellers = () =>
    setshowModalSellers((prevState) => !prevState);

  const { seconds, minutes, hours, start, reset, isRunning } = useStopwatch();

  const onOpenCloseConfirm = () => setShowConfirm((prevState) => !prevState);

  const openDeleteConfirm = () => {
    setConfirmMessage(`Eliminar turno ${turn.number}`);
    onOpenCloseConfirm();
  };

  const onBusy = async () => {
    if (!isRunning) {
      start();
      await userController.updateUser(accessToken, user?._id, {
        status: "Ocupado",
      });
      await turnController.updateTurn(accessToken, turn?._id, {
        status: "called",
      });
    } else {
      const time = `${("0" + hours).slice(-2)}:${("0" + minutes).slice(-2)}:${(
        "0" + seconds
      ).slice(-2)}`;
      reset(0, false);
      await userController.updateUser(accessToken, user?._id, {
        status: "Disponible",
      });
      await turnController.updateTurn(accessToken, turn?._id, {
        status: "finished",
        active: false,
      });
      await statusController.createStatus(accessToken, {
        user: user?._id,
        time,
        status: "Ocupado",
      });
      onReload();
    }
  };

  const onReactive = async () => {
    try {
      await turnController.updateTurn(accessToken, turn?._id, {
        status: "waiting",
        active: true,
      });
      onReload();
    } catch (error) {
      console.error(error);
    }
  };

  const onDelete = async () => {
    try {
      await turnController.deleteTurn(accessToken, turn?._id);
      onReload();
      onOpenCloseConfirm();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="turn-item">
        <div className="turn-item__info">
          <Image avatar src={image.noAvatar} />
          <div>
            <p>
              {turn.sector.initials}-{turn.number}
            </p>
            <p>Sector: {turn.sector?.name}</p>
          </div>
        </div>

        <div className="turn-item__buttons">
          {!turn.active && (
            <>
              <Button icon primary onClick={onReactive}>
                <Icon name="arrow left" />
              </Button>
              {/* <Button icon color="green" onClick={onOpenCloseModal}>
                <Icon name="add user" />
              </Button> */}
            </>
          )}
          {includes(user.role, "seller") && turn.active && (
            <>
              {isRunning && (
                <div className="turn-item__timer">
                  <span>{("0" + hours).slice(-2)}</span>:
                  <span>{("0" + minutes).slice(-2)}</span>:
                  <span>{("0" + seconds).slice(-2)}</span>
                </div>
              )}
              <Button icon primary onClick={onBusy}>
                {!isRunning ? <Icon name="bell" /> : <Icon name="bell slash" />}
              </Button>
              {/* <Button icon color="green" onClick={onOpenCloseModal}>
                <Icon name="add user" />
              </Button> */}
            </>
          )}
          {includes(user.role, "admin") && (
            <Button icon primary onClick={onOpenCloseModalSellers}>
              <Icon name="pencil" />
            </Button>
          )}

          {includes(user.role, "admin") && (
            <Button icon color="red" onClick={openDeleteConfirm}>
              <Icon name="trash" />
            </Button>
          )}
        </div>
      </div>

      <Confirm
        open={showConfirm}
        onCancel={onOpenCloseConfirm}
        onConfirm={onDelete}
        content={confirmMessage}
        size="mini"
      />

      {/* <BasicModal
        show={showModal}
        close={onOpenCloseModal}
        title="Crear nuevo sector"
      >
        <ClientForm
          close={onOpenCloseModal}
          onReload={onReload}
          client={turn.client}
        />
      </BasicModal> */}

      <BasicModal
        show={showModalSellers}
        close={onOpenCloseModalSellers}
        title="Actualizar vendedor"
      >
        <TurnForm
          close={onOpenCloseModalSellers}
          onReload={onReload}
          turn={turn}
        />
      </BasicModal>
    </>
  );
}
