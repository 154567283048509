import React from "react";
import { Menu, Icon } from "semantic-ui-react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../../../hooks";
import "./AdminMenu.scss";
import { includes } from "lodash";

export function AdminMenu() {
  const { pathname } = useLocation();
  const { user } = useAuth();

  const isAdmin = includes(user?.role, "admin");

  const isCurrentPath = (path) => {
    if (path === pathname) return true;
    return false;
  };

  return (
    <Menu fluid vertical icon text className="admin-menu">
      {isAdmin && (
        <>
          <Menu.Item
            as={Link}
            to="/admin/users"
            active={isCurrentPath("/admin/users")}
          >
            <Icon name="user" />
            Usuarios
          </Menu.Item>
          <Menu.Item
            as={Link}
            to="/admin/sectors"
            active={isCurrentPath("/admin/sectors")}
          >
            <Icon name="building" />
            Sectores
          </Menu.Item>
        </>
      )}
      {/* <Menu.Item
        as={Link}
        to="/admin/clients"
        active={isCurrentPath("/admin/clients")}
      >
        <Icon name="users" />
        Clientes
      </Menu.Item> */}
      <Menu.Item
        as={Link}
        to="/admin/turns"
        active={isCurrentPath("/admin/turns")}
      >
        <Icon name="ticket" />
        Turnos
      </Menu.Item>
    </Menu>
  );
}
