import React, { useState, useEffect } from "react";
import { Button } from "semantic-ui-react";
import { filter, includes, map } from "lodash";
import { Sector, Turn, User } from "../../../../api";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../hooks";

import "./ListSectors.scss";

const userController = new User();
const sectorController = new Sector();
const turnController = new Turn();

export function ListSectors() {
  const [sectors, setSectors] = useState(null);
  const [sellers, setSellers] = useState(null);
  const [sector, setSector] = useState(null);
  const navigate = useNavigate();
  const { accessToken } = useAuth();

  useEffect(() => {
    (async () => {
      try {
        const response = await sectorController.getSectors(true);
        setSectors(response);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const onSelect = async (sector, seller) => {
    let response;
    if (sector._id === "650c68864d4d2811d415924b" && seller) {
      response = await turnController.createTurn({
        sector: sector._id,
        seller: seller._id,
      });
      navigate("/h45h-w414.v3r0n4!/turn", {
        state: { turn: response, sector },
      });
    } else {
      response = await turnController.createTurn({
        sector: sector._id,
      });
      navigate("/h45h-w414.v3r0n4!/turn", {
        state: { turn: response, sector },
      });
    }
  };

  const selectSeller = async () => {
    const users = await userController.getUsers(accessToken, true, 1, 100);
    const usersSeguros = filter(users.docs, (item) =>
      includes(item.sector, "650c68864d4d2811d415924b")
    );
    setSellers(usersSeguros);
  };

  return (
    <div className="list-sectors">
      <h1>Seleccione un sector de atencion.</h1>
      <div className="touchables">
        {!sellers ? (
          <>
            {map(sectors, (sector) => (
              <div key={sector._id}>
                <Button
                  primary
                  fluid
                  onClick={() => {
                    if (sector._id === "650c68864d4d2811d415924b") {
                      setSector(sector);
                      selectSeller();
                    } else {
                      onSelect(sector, null);
                    }
                  }}
                >
                  {sector.name}
                </Button>
              </div>
            ))}
          </>
        ) : (
          <>
            {map(sellers, (seller) => (
              <div key={seller._id}>
                <Button
                  primary
                  fluid
                  onClick={() => {
                    onSelect(sector, seller);
                  }}
                >
                  {seller.firstname + " " + seller.lastname}
                </Button>
              </div>
            ))}
            <div>
              <Button
                primary
                fluid
                onClick={() => {
                  onSelect(sector, null);
                }}
              >
                {"No tengo vendedor asignado"}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
