import React, { useState, useEffect } from "react";
import { Loader } from "semantic-ui-react";
import { size, map } from "lodash";
import { Sector } from "../../../../api";
import { SectorItem } from "../SectorItem";

const sectorController = new Sector();

export function ListSectors(props) {
  const { sectorsActive, reload, onReload } = props;
  const [sectors, setSectors] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await sectorController.getSectors(sectorsActive);
        setSectors(response);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [sectorsActive, reload]);

  if (!sectors) return <Loader active inline="centered" />;
  if (size(sectors) === 0) return "No hay ningun sector";

  return map(sectors, (sector) => (
    <SectorItem key={sector._id} sector={sector} onReload={onReload} />
  ));
}
