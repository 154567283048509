import { ENV } from "../utils";

export class Turn {
  baseApi = ENV.TURN.BASE_API;

  async createTurn(data) {
    try {
      const url = `${this.baseApi}/${ENV.TURN.API_ROUTES.TURN}`;
      const params = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };

      const response = await fetch(url, params);
      const result = response.json();
      if (response.status !== 200) throw result;
      return result;
    } catch (error) {
      throw error;
    }
  }

  async getTurns(active, id, page, limit) {
    try {
      const activeFilter = `active=${active}`;
      const idFilter = `id=${id}`;
      const pageFilter = `page=${page || 1}`;
      const limitFilter = `limit=${limit || 10}`;
      const url = `${this.baseApi}/${ENV.TURN.API_ROUTES.TURNS}?${activeFilter}&${idFilter}&${pageFilter}&${limitFilter}`;
      const response = await fetch(url);
      const result = response.json();
      if (response.status !== 200) throw result;
      return result;
    } catch (error) {
      throw error;
    }
  }

  async getTurnsByStatus(status, page, limit) {
    try {
      const statusFilter = `status=${status}`;
      const pageFilter = `page=${page || 1}`;
      const limitFilter = `limit=${limit || 10}`;
      const url = `${this.baseApi}/${ENV.TURN.API_ROUTES.STATUS}?${statusFilter}&${pageFilter}&${limitFilter}`;
      const response = await fetch(url);
      const result = response.json();
      if (response.status !== 200) throw result;
      return result;
    } catch (error) {
      throw error;
    }
  }

  async updateTurn(accessToken, idTurn, data) {
    try {
      const url = `${this.baseApi}/${ENV.TURN.API_ROUTES.TURN}/${idTurn}`;
      const params = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      };

      const response = await fetch(url, params);
      const result = response.json();
      if (response.status !== 200) throw result;
      return result;
    } catch (error) {
      throw error;
    }
  }

  async deleteTurn(accessToken, idTurn) {
    try {
      const url = `${this.baseApi}/${ENV.TURN.API_ROUTES.TURN}/${idTurn}`;
      const params = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const response = await fetch(url, params);
      const result = response.json();
      if (response.status !== 200) throw result;
      return result;
    } catch (error) {
      throw error;
    }
  }
}
