import * as Yup from "yup";

export function initialValues(sector) {
  return {
    name: sector?.name || "",
    initials: sector?.initials || "",
  };
}

export function validationSchema() {
  return Yup.object({
    name: Yup.string().required(true),
    initials: Yup.string().required(true),
  });
}
