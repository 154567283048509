import React from "react";
import { BrowserRouter } from "react-router-dom";
import { AdminRouter, WebRouter } from "./router";
import { AuthProvider } from "./contexts";
import { ToastProvider } from "react-toast-notifications";

export default function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <ToastProvider>
          <AdminRouter />
          <WebRouter />
        </ToastProvider>
      </BrowserRouter>
    </AuthProvider>
  );
}
