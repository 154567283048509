import React, { useState } from "react";
import { Image, Button, Icon, Confirm } from "semantic-ui-react";
import { image } from "../../../../assets";
import { ENV } from "../../../../utils";
import { BasicModal } from "../../../Shared";
import { Sector } from "../../../../api";
import { useAuth } from "../../../../hooks";
import { SectorForm } from "../SectorForm";

import "./SectorItem.scss";

const sectorController = new Sector();

export function SectorItem(props) {
  const { sector, onReload } = props;
  const { accessToken } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState("");

  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [isDelete, setIsDelete] = useState(false);

  const onOpenCloseModal = () => setShowModal((prevState) => !prevState);
  const onOpenCloseConfirm = () => setShowConfirm((prevState) => !prevState);

  const openUpdateSector = () => {
    setTitleModal(`Actualizar ${sector.name}`);
    onOpenCloseModal();
  };

  const openDesactivateActivateConfirm = () => {
    setIsDelete(false);
    setConfirmMessage(
      sector.active
        ? `Desactivar usuario ${sector.name}`
        : `Activar usuario ${sector.name}`
    );
    onOpenCloseConfirm();
  };

  const onActivateDesactivate = async () => {
    try {
      await sectorController.updateSector(accessToken, sector._id, {
        active: !sector.active,
      });
      onReload();
      onOpenCloseConfirm();
    } catch (error) {
      console.error(error);
    }
  };

  const openDeleteConfirm = () => {
    setIsDelete(true);
    setConfirmMessage(`Eliminar usuario ${sector.name}`);
    onOpenCloseConfirm();
  };

  const onDelete = async () => {
    try {
      await sectorController.deleteSector(accessToken, sector._id);
      onReload();
      onOpenCloseConfirm();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="sector-item">
        <div className="sector-item__info">
          <Image avatar src={image.noAvatar} />
          <div>
            <p>{sector.name}</p>
            <p>{sector.initials}</p>
          </div>
        </div>
        <div>
          <Button icon primary onClick={openUpdateSector}>
            <Icon name="pencil" />
          </Button>
          <Button
            icon
            color={sector.active ? "orange" : "teal"}
            onClick={openDesactivateActivateConfirm}
          >
            <Icon name={sector.active ? "ban" : "check"} />
          </Button>
          <Button icon color="red" onClick={openDeleteConfirm}>
            <Icon name="trash" />
          </Button>
        </div>
      </div>

      <BasicModal show={showModal} close={onOpenCloseModal} title={titleModal}>
        <SectorForm
          close={onOpenCloseModal}
          onReload={onReload}
          sector={sector}
        />
      </BasicModal>

      <Confirm
        open={showConfirm}
        onCancel={onOpenCloseConfirm}
        onConfirm={isDelete ? onDelete : onActivateDesactivate}
        content={confirmMessage}
        size="mini"
      />
    </>
  );
}
