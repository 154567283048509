import { ENV } from "../utils";

export class User {
  baseApi = ENV.USER.BASE_API;

  async getMe(accessToken) {
    try {
      const url = `${this.baseApi}/${ENV.USER.API_ROUTES.USER_ME}`;
      const params = {
        headers: { Authorization: `Bearer ${accessToken}` },
      };
      const response = await fetch(url, params);
      const result = response.json();
      if (response.status !== 200) throw result;
      return result;
    } catch (error) {
      throw error;
    }
  }

  async createUser(accessToken, data) {
    try {
      const url = `${this.baseApi}/${ENV.USER.API_ROUTES.USER}`;
      const params = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      };

      const response = await fetch(url, params);
      const result = response.json();
      if (response.status !== 200) throw result;
      return result;
    } catch (error) {
      throw error;
    }
  }

  async getUsers(accessToken, active = undefined, page, limit) {
    const pageFilter = `page=${page || 1}`;
    const limitFilter = `limit=${limit || 10}`;
    try {
      const url = `${this.baseApi}/${ENV.USER.API_ROUTES.USERS}?active=${active}&${pageFilter}&${limitFilter}`;
      const params = {
        headers: { Authorization: `Bearer ${accessToken}` },
      };
      const response = await fetch(url, params);
      const result = response.json();
      if (response.status !== 200) throw result;
      return result;
    } catch (error) {
      throw error;
    }
  }

  async updateUser(accessToken, idUser, userData) {
    try {
      const data = userData;
      if (!data.password) delete data.password;

      const url = `${this.baseApi}/${ENV.USER.API_ROUTES.USER}/${idUser}`;
      const params = {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data),
      };

      const response = await fetch(url, params);
      const result = response.json();
      if (response.status !== 200) throw result;
      return result;
    } catch (error) {
      throw error;
    }
  }

  async deleteUser(accessToken, idUser) {
    try {
      const url = `${this.baseApi}/${ENV.USER.API_ROUTES.USER}/${idUser}`;
      const params = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const response = await fetch(url, params);
      const result = response.json();
      if (response.status !== 200) throw result;
      return result;
    } catch (error) {
      throw error;
    }
  }
}
