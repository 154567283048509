import React from "react";
import { Loader } from "semantic-ui-react";
import { map } from "lodash";

import "./ListTurns.scss";

export function ListTurns(props) {
  const { turns, type } = props;

  if (!turns) return <Loader active inline="centered" />;

  return (
    <div className="turns">
      <div className="turns__body">
        {map(turns, (turn) => (
          <div
            key={turn._id}
            style={{
              backgroundColor: type === "waiting" ? "#9f3a38" : "#84b84c",
            }}
          >
            <span>{`${turn.sector.initials}-${("0" + turn.number).slice(
              -2
            )}`}</span>
            <span>Box {turn.seller?.box || 0}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
