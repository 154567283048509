import React, { useState } from "react";
import { Button, Form, Icon } from "semantic-ui-react";
import { useFormik } from "formik";
import { initialValues, validationSchema } from "./NewTurn.form";
import { useNavigate } from "react-router-dom";
import { Client } from "../../../../api";

import "./NewTurns.scss";

const clientController = new Client();

export function NewTurns() {
  const [dni, setDni] = useState("");
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: initialValues(dni),
    validationSchema: validationSchema(),
    validateOnChange: false,
    onSubmit: async (formValues) => {
      try {
        let response = await clientController.createClient(formValues);
        if (response.msg) {
          const data = await clientController.getClients(null, formValues.dni);
          response = data.docs;
        }
        setDni("");
        formik.resetForm();
        navigate("/h45h-w414.v3r0n4!/sectors", {
          state: { client: response[0] ? response[0] : response },
        });
      } catch (error) {
        console.error(error);
      }
    },
  });

  const handleAddNumber = (value) => {
    formik.setFieldValue("dni", dni + value);
    setDni(dni + value);
  };

  const onDelete = () => {
    let data = formik.values.dni;
    formik.setFieldValue("dni", data.slice(0, -1));
    setDni(data.slice(0, -1));
  };

  return (
    <div className="new-turns">
      <h1>Ingrese su numero de documento:</h1>
      <Form>
        <Form.Input name="dni" disabled value={dni} error={formik.errors.dni} />
      </Form>
      <div className="touchables">
        <div>
          <Button fluid primary onClick={() => handleAddNumber("1")}>
            1
          </Button>
        </div>
        <div>
          <Button fluid primary onClick={() => handleAddNumber("2")}>
            2
          </Button>
        </div>
        <div>
          <Button fluid primary onClick={() => handleAddNumber("3")}>
            3
          </Button>
        </div>
        <div>
          <Button fluid primary onClick={() => handleAddNumber("4")}>
            4
          </Button>
        </div>
        <div>
          <Button fluid primary onClick={() => handleAddNumber("5")}>
            5
          </Button>
        </div>
        <div>
          <Button fluid primary onClick={() => handleAddNumber("6")}>
            6
          </Button>
        </div>
        <div>
          <Button fluid primary onClick={() => handleAddNumber("7")}>
            7
          </Button>
        </div>
        <div>
          <Button fluid primary onClick={() => handleAddNumber("8")}>
            8
          </Button>
        </div>
        <div>
          <Button fluid primary onClick={() => handleAddNumber("9")}>
            9
          </Button>
        </div>
        <div>
          <Button fluid primary onClick={() => handleAddNumber("0")}>
            0
          </Button>
        </div>
        <div>
          <Button
            fluid
            primary
            onClick={onDelete}
            className="touchables__delete"
          >
            <Icon name="delete" />
          </Button>
        </div>
      </div>
      <div className="button">
        <Button
          primary
          fluid
          type="submit"
          loading={formik.isSubmitting}
          onClick={formik.handleSubmit}
        >
          Continuar
        </Button>
      </div>
    </div>
  );
}
