import React from "react";
import { Form } from "semantic-ui-react";
import { useFormik } from "formik";
import { Client } from "../../../../api";
import { useAuth } from "../../../../hooks";
import { initialValues, validationSchema } from "./ClientForm.form";

import "./ClientForm.scss";

const clientController = new Client();

export function ClientForm(props) {
  const { close, onReload, client } = props;
  const { accessToken } = useAuth();

  const formik = useFormik({
    initialValues: initialValues(client),
    validationSchema: validationSchema(),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        if (!client) {
          await clientController.createClient(formValue);
        } else {
          await clientController.updateClient(
            accessToken,
            client._id,
            formValue
          );
        }
        onReload();
        close();
      } catch (error) {
        console.error(error);
      }
    },
  });

  return (
    <Form className="client-form" onSubmit={formik.handleSubmit}>
      <Form.Group widths="equal">
        <Form.Input
          name="firstname"
          placeholder="Nombre"
          onChange={formik.handleChange}
          value={formik.values.firstname}
          error={formik.errors.firstname}
        />
        <Form.Input
          name="lastname"
          placeholder="Apellido"
          onChange={formik.handleChange}
          value={formik.values.lastname}
          error={formik.errors.lastname}
        />
      </Form.Group>
      <Form.Input
        name="email"
        placeholder="Email"
        onChange={formik.handleChange}
        value={formik.values.email}
        error={formik.errors.email}
      />
      <Form.Group widths="equal">
        <Form.Input
          name="phone"
          placeholder="Telefono"
          onChange={formik.handleChange}
          value={formik.values.phone}
          error={formik.errors.phone}
        />
        <Form.Input
          name="dni"
          placeholder="DNI"
          onChange={formik.handleChange}
          value={formik.values.dni}
          error={formik.errors.dni}
        />
      </Form.Group>
      <Form.Button type="submit" primary fluid loading={formik.isSubmitting}>
        {client ? "Actualizar cliente" : "Crear cliente"}
      </Form.Button>
    </Form>
  );
}
