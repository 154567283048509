import React, { useState } from "react";
import { Button, Icon, Dropdown } from "semantic-ui-react";
import { useAuth } from "../../../../hooks";
import { BasicModal } from "../../../Shared";
import { ChangePasswordForm } from "../../../Admin/Users";

export function Profile() {
  const { user } = useAuth();
  const [showPasswordForm, setShowPasswordForm] = useState(false);

  const onOpenPasswordForm = () =>
    setShowPasswordForm((prevState) => !prevState);

  return (
    <>
      <Button icon>
        <Icon name="user" />{" "}
        <Dropdown text={user.firstname + " " + user.lastname}>
          <Dropdown.Menu>
            <Dropdown.Item
              text="Cambiar contraseña"
              onClick={onOpenPasswordForm}
            />
          </Dropdown.Menu>
        </Dropdown>
      </Button>

      <BasicModal
        show={showPasswordForm}
        close={onOpenPasswordForm}
        title={"Cambiar constraseña"}
      >
        <ChangePasswordForm close={onOpenPasswordForm} />
      </BasicModal>
    </>
  );
}
