import { useFormik } from "formik";
import React from "react";
import { Form } from "semantic-ui-react";
import { initialValues, validationSchema } from "./ChangePasswordForm.form";
import { User } from "../../../../api";
import { useAuth } from "../../../../hooks";

const userController = new User();

export function ChangePasswordForm(props) {
  const { close } = props;
  const { user, accessToken, logout } = useAuth();
  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: validationSchema(),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        await userController.updateUser(accessToken, user._id, {
          password: formValue.password,
        });
        close();
        logout();
      } catch (error) {
        console.error(error);
      }
    },
  });

  return (
    <Form className="changepassword-form" onSubmit={formik.handleSubmit}>
      <Form.Input
        type="password"
        name="password"
        placeholder="Contraseña"
        onChange={formik.handleChange}
        value={formik.values.password}
        error={formik.errors.password}
      />
      <Form.Button type="submit" primary fluid loading={formik.isSubmitting}>
        Cambiar Contraseña
      </Form.Button>
    </Form>
  );
}
