import React from "react";
import { Routes, Route } from "react-router-dom";
import { ClientLayout } from "../layouts";
import { Home, Sectors, Turn, Monitor, Error } from "../pages/web";

export function WebRouter() {
  const loadLayout = (Layout, Page) => {
    return (
      <Layout>
        <Page />
      </Layout>
    );
  };

  return (
    <Routes>
      <Route path="/" element={loadLayout(ClientLayout, Error)} />
      {/* <Route
        path="/h45h-w414.v3r0n4!"
        element={loadLayout(ClientLayout, Home)}
      /> */}
      <Route
        path="/h45h-w414.v3r0n4!"
        element={loadLayout(ClientLayout, Sectors)}
      />
      <Route
        path="/h45h-w414.v3r0n4!/turn"
        element={loadLayout(ClientLayout, Turn)}
      />
      <Route path="/monitor" element={Monitor()} />
    </Routes>
  );
}
