import React from "react";
import { Container } from "semantic-ui-react";
import { ListSectors } from "../../../components/Web";

import "./Sectors.scss";

export function Sectors() {
  return (
    <Container className="sectors">
      <ListSectors />
    </Container>
  );
}
